import React from 'react'

import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import TableHeaderCellStyled from '../common/TableHeaderCellStyled';
import UserPreview from './UserPreview';
import { UserDto } from '../../api/UserManagementApi';

interface UsersTableProps{
    style?:any
    showCheckboxes?:boolean,
    linkInNewWindow?:boolean,
    rows: UserDto[],
    rowsPerPage:any,
    page:any,
    handleChangeRowsPerPage: any,
    handleChangePage: any,
    count?: any,
}

export default function UsersTable(props: UsersTableProps ){
    const { t } = useTranslation();

    const columns = [
        { key: 'username', label: t("USERNAME"), width: "20%" },
        { key: 'firstname', label: t("FIRSTNAME"), width: "15%" },
        { key: 'surname', label: t("SURNAME"), width: "15%" },
        { key: 'roles', label: t("ROLES"), width: "40%" },
    ];
    props.showCheckboxes==true && columns.push({ key: 'selected', label: t("SELECT"), width: "5%"})

    return (
        <Table style={props.style} aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableHeaderCellStyled
                            key={column.key}
                            width={column.width}
                            label={column.label}
                            border={true}
                        />
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.rows.map((row) => {
                    return (
                        <UserPreview key={row.id} id={row.id} username={row.username} firstname={row.firstName} surname={row.lastName} roles={row.roles} included={props.showCheckboxes==true ? row.selected : null} handleIncluded={(value)=>{row.selected=value}} linkInNewWindow={props.linkInNewWindow}/>
                        );
                })}
            </TableBody>
            <TableFooter>
                {(props.count>5 || props.rows.length>5) && 
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 30, (props.count<1000 ? { label: t("ALL"), value: 1000 } : 100)]}
                            count={props.count!=null ? props.count : props.rows.length}
                            rowsPerPage={props.rowsPerPage}
                            page={props.page}
                            SelectProps={{
                                inputProps: { 'aria-label': t("ROWSPERPAGE") },
                                native: true,
                            }}
                            labelRowsPerPage={t("ROWSPERPAGE")}
                            labelDisplayedRows={({ from, to, count }) =>from + '-' + to + t("ROWSOF") + count}
                            onPageChange={props.handleChangePage}
                            onRowsPerPageChange={props.handleChangeRowsPerPage}
                        />
                    </TableRow>
                }
            </TableFooter>
        </Table>
    )
}