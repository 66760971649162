import React, {useEffect} from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
import UsersManagement from '../../components/users/UsersManagement';
import { useUserContext } from '../../context/UserContext';
import { Authority } from '../../api/RoleManagementApi';

export default function Index() {
    const { authorities } = useUserContext();

    useEffect(() => {
        authorities.includes(Authority.USER_MANAGEMENT)==false && navigate("/")
    },[]);
    return (
        <Layout allowAnonymous={false}>
            <UsersManagement style={{minWidth:700, width:"75%", marginLeft: "auto", marginRight: "auto"}}/>
        </Layout>
    )
}