import React, {useEffect, useState} from 'react'
import { TableRow, TableCell, Checkbox } from "@material-ui/core";
import {navigate} from "gatsby";

import { useStyles } from "../../styles/TableStyles";
import { Harmonized, InternalCodeDto } from '../../api/StandardApi';

import { useTranslation } from 'react-i18next';
import { RoleDto } from '../../api/RoleManagementApi';

interface UserPreviewProps {
    id:number,
    username: string,
    firstname: string,
    surname: string,
    roles: RoleDto[],
    included?:boolean,
    handleIncluded?:any,
    linkInNewWindow?:boolean,
}

export default function UserPreview(props: UserPreviewProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [checked, setChecked] = useState<boolean>(props.included);
    useEffect(()=>{
        setChecked(props.included)
    },[props.included])

    return (
        <TableRow className={classes.childRowWithLink}  hover>
            <TableCell style={checked==true ? {background:"#F5F5F5"} : {}} onClick={()=>props.linkInNewWindow==true ? window.open("/users/"+props.id, "_blank") : navigate("/users/"+props.id)}  key={props.id+"id"} className={classes.cellWithBorder} align="center">
                {props.username}
            </TableCell>
            <TableCell style={checked==true ? {background:"#F5F5F5"} : {}} onClick={()=>props.linkInNewWindow==true ? window.open("/users/"+props.id, "_blank") : navigate("/users/"+props.id)}  key={props.id+"firstname"} className={classes.cellWithBorder} align="left">
                {props.firstname}
            </TableCell>
            <TableCell style={checked==true ? {background:"#F5F5F5"} : {}} onClick={()=>props.linkInNewWindow==true ? window.open("/users/"+props.id, "_blank") : navigate("/users/"+props.id)}  key={props.id+"surname"} className={classes.cellWithBorder} align="left">
                {props.surname}
            </TableCell>
            {props.roles!=null &&  
            <TableCell style={checked==true ? {background:"#F5F5F5"} : {}} onClick={()=>props.linkInNewWindow==true ? window.open("/users/"+props.id, "_blank") : navigate("/users/"+props.id)}  key={props.id+"internalCodes"} className={classes.cellWithBorder} align="left">
                {props.roles.map(role=>role.name).join(", ")}
            </TableCell>}
            {props.included!=null &&  
             <TableCell style={checked==true ? {background:"#F5F5F5"} : {}} key={props.id+"included"} className={classes.cellWithBorder} align="left">
                <Checkbox
                    checked={checked}
                    value={props.included}
                    onChange={(event)=>{
                        props.handleIncluded(event.target.checked)
                        setChecked(event.target.checked)
                        }}
                    color="primary"
                    inputProps={{ 'aria-label': 'include checkbox' }}
                />
            </TableCell>}
        </TableRow>
    );
}