import React, {useEffect, useState} from 'react'

import AutocompleteStyled from "../common/AutocompleteStyled";
import {navigate} from "gatsby";

import { assignRoles, deleteRoles, getUsers, UserDto } from "../../api/UserManagementApi"
import InfoHover from "../common/InfoHover";
import ConfirmDialog from "../common/ConfirmDialog";
import ButtonStyled from "../common/ButtonStyled";

import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import UsersTable from './UsersTable';
import { Paper, CardActions, TextField } from '@material-ui/core';
import { getRoles, RoleDto } from '../../api/RoleManagementApi';
import { Autocomplete } from '@material-ui/lab';

interface UsersManagementProps{
    style?:any
}

export default function UsersManagement(props: UsersManagementProps ){
    const classes = useStyles();
    const { t } = useTranslation();

    const [usersRows, setUsersRows] = useState<UserDto[]>([]);
    const [size, setSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [totalUsersCount, setTotalUsersCount] = useState<number>(0);

    const [selectedRoles, setSelectedRoles] = useState<RoleDto[]>([]);
    const [fullRoles, setFullRoles] = useState<RoleDto[]>([]);

    const [addRolesDialogOpen, setAddRolesDialogOpen] = useState<boolean>(false);
    const [removeRolesDialogOpen, setRemoveRolesDialogOpen] = useState<boolean>(false);

    const getCurrentRows = (page, size) => {
        getUsers(page, size, null).then(data => {
                /*data.forEach(row => {
                    let tmp = []
                    row.internalCodes.forEach(code => {
                        tmp.push(icodes.find(icode => icode.id == code).name)
                    })
                    row.internalCodes = tmp
                })*/
                data.content.forEach(user => user.selected=false)
                setUsersRows(data.content)
                
                setTotalUsersCount(data.totalElements)
                getRoles(null,null,null).then(rolesData => {
                    setFullRoles(rolesData.content)
                }).catch(err => console.log("Error getting all roles",err))
        }).catch(err => console.log("Error getting users rows ",err))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var limit = parseInt(event.target.value, 10)
        getCurrentRows(0, limit)
        setSize(limit);
        setPage(0)
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        getCurrentRows(newPage, size)
        setPage(newPage)
    };

    const addRoles = () => {
        Promise.resolve(usersRows.forEach(user => {
            user.selected==true && assignRoles(user.id,selectedRoles.map(role=>role.id).join(", "))
        })).then(()=>{
            getCurrentRows(page,size)
            setAddRolesDialogOpen(false)
        })  
    }

    const removeRoles = () => {
        Promise.resolve(usersRows.forEach(user => {
            user.selected==true && deleteRoles(user.id,selectedRoles.map(role=>role.id).join(", "))
        })).then(()=>{
            getCurrentRows(page,size)
            setRemoveRolesDialogOpen(false)
        })      
    }

    useEffect(() => {
        getCurrentRows(null,null)
    },[]);

    return (
        <Paper className={classes.root} style={props.style}>
            <div className={classes.filterContainer}>
                <ButtonStyled
                    style={{width:300}}
                    text={t("REGISTERUSER")}
                    onClick={() => navigate("/users/create")}/>
                <div className={classes.flexBreak} style={{height:10}}/>
                <AutocompleteStyled label={t("SELECTROLES")} value={selectedRoles} multiple={true}
                                    options={fullRoles!=null ? fullRoles : []}
                                    customOptionLabel={true} optionLabel="name"
                                    handleValueFunction={setSelectedRoles} style={{width: "40%", minWidth: 300, marginBottom:0}}/>
                <InfoHover info={t("BULKROLESINFO")}/>
                <ButtonStyled
                    text={t("ADDROLESTOUSERS")}
                    style={{marginBottom:0}}
                    onClick={() => {
                        setAddRolesDialogOpen(true)
                    }}/>
                <ConfirmDialog
                    title={t("SAVECHANGES")}
                    open={addRolesDialogOpen}
                    onClose={()=>setAddRolesDialogOpen(false)}
                    onConfirm={addRoles}
                >
                    {t("AREYOUSURETO") + " " + t("ADDROLES") + " " + selectedRoles.map(role=>role.name).join(", ") + " " +  t("FROM") + " " + usersRows.filter(user=>user.selected==true).map(user=>user.username).join(", ")}
                </ConfirmDialog>
                <ButtonStyled
                    style={{marginLeft:10}}
                    text={t("REMOVEROLESFROMUSERS")}
                    onClick={() => {
                        setRemoveRolesDialogOpen(true)
                    }}/>
                <ConfirmDialog
                    title={t("SAVECHANGES")}
                    open={removeRolesDialogOpen}
                    onClose={()=>setRemoveRolesDialogOpen(false)}
                    onConfirm={removeRoles}
                >
                   {t("AREYOUSURETO") + " " + t("REMOVEROLES") + " " + selectedRoles.map(role=>role.name).join(", ") + " " + t("FROM") + " " + usersRows.filter(user=>user.selected==true).map(user=>user.username).join(", ")}   
                </ConfirmDialog>
            </div>
            <UsersTable style={{marginTop:10}} rows={usersRows} showCheckboxes={true} rowsPerPage={size} handleChangeRowsPerPage={handleChangeRowsPerPage} count={totalUsersCount} page={page} handleChangePage={handleChangePage}/>    
            {/*<CardActions className={classes.cardActions}>
                <ButtonStyled
                    style={{marginRight: "auto"}}
                    text={t("ROLEMANAGEMENT")}
                    onClick={() => navigate("/users/roles")}/>
                </CardActions>*/}
        </Paper>
        
    )
}